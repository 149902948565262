import { Controller } from "@hotwired/stimulus"
import Plotly from "plotly.js-dist-min";

export default class extends Controller {
  connect() {
    Plotly.newPlot(
      this.data.get('id'),
      JSON.parse(this.data.get('data')),
      JSON.parse(this.data.get('layout'))
    );
  }
}
