import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug    = false
window.Stimulus      = application

window.bootstrap = require('bootstrap');

export { application }

const ready = () => {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  var myCarousels = document.querySelectorAll('.carousel')
  myCarousels.forEach(e => {
    new bootstrap.Carousel(e)
  })
}
document.addEventListener('turbolinks:load', ready)

