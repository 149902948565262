// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

window.Rails = Rails

import "bootstrap"
import "bootstrap-icons/font/bootstrap-icons.css"
import "sortablejs/Sortable.min.js"
import "plotly.js-dist-min/plotly.min.js"
import "html2canvas/dist/html2canvas.min.js"
import "jspdf/dist/jspdf.node.min.js"
import "../stylesheets/application"
import "../controllers"


Rails.start()
Turbolinks.start()
ActiveStorage.start()
