import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["collapsable"]

  htmlElement

  connect() {
    this.htmlElement = this.data.get('htmlElement')
    if (localStorage.getItem(this.htmlElement) === 'true') {
      this.collapsableTargets.forEach(e => {
        new bootstrap.Collapse(e, {
          show: true
        })
      })
    }
  }

  toggle(event) {
    if (this.collapsableTargets[0].classList.contains('collapsed')) {
      localStorage.setItem(this.htmlElement, 'false')
    } else {
      localStorage.setItem(this.htmlElement, 'true')
    }
  }
}
