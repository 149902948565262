import { Controller } from "@hotwired/stimulus"
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default class extends Controller {
  create() {
    const data = document.getElementById(this.data.get('id'));
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'px', 'a4');
      const imgProps = pdf.getImageProperties(contentDataURL);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(contentDataURL, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`Report analisi funzionale.pdf`);
    });
  }
}
