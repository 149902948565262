import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: {
        name: 'shared',
        pull: false
      },
      animation: 150,
      handle: ".draggable",  // Specifies which items inside the element should be draggable
      onEnd: this.end.bind(this),
      forceFallback: true
    })
  }

  end(event) {
    let id = event.item.dataset.id
    let data = new FormData()
    data.append("position", event.newIndex + 1)

    Rails.ajax({
      url: this.data.get('url').replace(":id", id),
      type: 'PATCH',
      data: data
    })
  }
}
